import { hasNames } from "../utils";
import * as React from "react";
import { useEffect, useState } from "react";



import LogoLeft from "../images/split-logo-left.svg";
import LogoRight from "../images/split-logo-right.svg";



import * as grd from "../styles/grid.module.scss";
import * as typ from "../styles/typography.module.scss";
import * as styles from "../styles/why-it-matters.module.scss";


// These values will be used to adjust the final position of the logo halfs
const logoPosAdj = {
	left: {
		top: 2,
	},
	right: {
		bottom: 2,
	},
};

const WhyItMatters = ({ useRef = null }) => {
	const containerRef = React.useRef();
	const logoRef = React.useRef();
	const [runAnimation, setRunAnimation] = useState(true);
	const [browOpacity, setBrowOpacity] = useState(0.0);
	const [messageOpacity, setMessageOpacity] = useState(0.0);
	const [logoLeftValues, setLogoLeftValues] = useState({
		height: 1000,
		top: 0,
		left: -1000,
	});
	const [logoRightValues, setLogoRightValues] = useState({
		height: 1000,
		bottom: 0,
		right: -1000,
	});

	function scale(number, outMin, outMax, inMin = 0, inMax = 1) {
		return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
	}

	function onAnimationFrame() {
		const elPos = document
			.querySelector("." + styles.main)
			?.getBoundingClientRect();
		if (elPos === undefined) {
			return;
		}

		const containerBox = containerRef.current.getBoundingClientRect();
		const logoBox = logoRef.current.getBoundingClientRect();

		const masterAnimationPercentage = Math.min(
			Math.max((elPos.top * -1) / (elPos.height - window.innerHeight), 0),
			1
		);

		const logoAnimationPercentage = Math.min(
			masterAnimationPercentage / 0.8,
			1
		);
		const widthAdjustment = (window.innerWidth - containerBox.width) / 2;

		// Update logo part position
		let newLeftValues = { ...logoLeftValues };
		newLeftValues.height = 50 + 950 * (1 - logoAnimationPercentage);
		newLeftValues.left = scale(
			logoAnimationPercentage,
			-1150 - widthAdjustment,
			logoBox.left - widthAdjustment
		);
		newLeftValues.top = scale(
			logoAnimationPercentage,
			0,
			logoBox.top - containerBox.top + logoPosAdj.left.top
		);
		setLogoLeftValues(newLeftValues);

		let newRightValues = { ...logoRightValues };
		newRightValues.height = 50 + 950 * (1 - logoAnimationPercentage);
		newRightValues.right = scale(
			logoAnimationPercentage,
			-1150 - widthAdjustment,
			logoBox.left - widthAdjustment
		);
		newRightValues.bottom = scale(
			logoAnimationPercentage,
			0,
			window.innerHeight -
				logoBox.top +
				containerBox.top -
				logoBox.height +
				logoPosAdj.right.bottom
		);
		setLogoRightValues(newRightValues);

		// Manage the text opacity
		setBrowOpacity(
			Math.min(
				masterAnimationPercentage < 0.7
					? 0
					: (masterAnimationPercentage - 0.7) / 0.2,
				1
			)
		);
		setMessageOpacity(
			Math.min(
				masterAnimationPercentage < 0.8
					? 0
					: (masterAnimationPercentage - 0.8) / 0.18,
				1
			)
		);

		// If there is a good reason keep at it
		if (runAnimation) {
			window.requestAnimationFrame(onAnimationFrame);
		}
	}

	useEffect(() => {
		window.requestAnimationFrame(onAnimationFrame);
		return () => {
			setRunAnimation(false);
		};
	});

	return (
		<>
			<div className={hasNames(styles.main)} ref={useRef}>
				<div
					className={hasNames(styles.container, grd.container)}
					ref={containerRef}>
					<div>
						<div className={hasNames(styles.logoSpot)} ref={logoRef}></div>
						<div
							className={hasNames(styles.brow, typ.eyebrow)}
							style={{ opacity: browOpacity }}>
							Why it matters
						</div>
						<h2
							className={hasNames(styles.statement)}
							style={{ opacity: messageOpacity }}>
							Alignment matters and our goal is to empower our partners to achieve their vision for their company.{" "}
						</h2>
					</div>
					<img
						src={LogoLeft}
						className={hasNames(styles.logoElement)}
						aria-hidden="true"
						alt=""
						style={{
							height: logoLeftValues.height + "px",
							top: logoLeftValues.top + "px",
							left: logoLeftValues.left + "px",
						}}
					/>
					<img
						src={LogoRight}
						className={hasNames(styles.logoElement)}
						aria-hidden="true"
						alt=""
						style={{
							height: logoRightValues.height + "px",
							bottom: logoRightValues.bottom + "px",
							right: logoRightValues.right + "px",
						}}
					/>
				</div>
			</div>
		</>
	);
};

export default WhyItMatters;
