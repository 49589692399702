import { hasNames } from "../utils";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { useRef } from "react";



import Button from "../components/button";
import ContactUsFooter from "../components/contact-us-footer";
import FiftyFifty from "../components/fifty-fifty";
import Frame from "../components/frame";
import { HeadMeta } from "../components/head-meta";
import InteractiveScroll from "../components/interactive-scroll";
import InternalHero from "../components/internal-hero";
import QuoteCarousel from "../components/quote-carousel";
import TriplePoints from "../components/triple-points";
import WhyItMatters from "../components/why-it-matters";



import BeliefGraphic from "../images/belief-graphic.inline.svg";
import GrowthLeaf from "../images/growth.leaf.svg";
import HighFiveLeaf from "../images/high-five.leaf.svg";
import IdeaLeaf from "../images/idea.leaf.svg";

import * as styles from "../styles/approach.module.scss";
import * as com from "../styles/common.module.scss";
import * as grd from "../styles/grid.module.scss";
import * as typ from "../styles/typography.module.scss";

export const Head = () => {
	return <HeadMeta title="Approach" />;
};

const OurApproachPage = () => {
	const heroRef = useRef(null);
	const capitalRef = useRef(null);
	const ownershipRef = useRef(null);
	const alignmentRef = useRef(null);
	const mattersRef = useRef(null);
	const beliefRef = useRef(null);
	const quotesRef = useRef(null);
	const contactRef = useRef(null);

	const scrollLocations = [
		{ label: "Approach", ref: heroRef },
		{ label: "Flexible Capital", ref: capitalRef },
		{ label: "Ownership", ref: ownershipRef },
		{ label: "Alignment", ref: alignmentRef },
		{ label: "Why it Matters", ref: mattersRef },
		{ label: "Our Belief", ref: beliefRef },
		{ label: "What Founders Say", ref: quotesRef },
		{ label: "Footer", ref: contactRef },
	];

	return (
		<Frame>
			<InteractiveScroll locations={scrollLocations} />
			<InternalHero
				ref={heroRef}
				eyebrow={"Approach"}
				backdrop={
					<StaticImage
						src={`../images/heros/meeting.jpg`}
						alt="background image"
						aria-hidden="true"
						placeholder="blurred"
						layout="fullWidth"
						loading="eager"
					/>
				}>
				<h1>A partnership tailored to your success.</h1>
				<p>
					We want to find Founders and Management Owners with big ideas who are
					not done building and want a partner to provide both tailored capital
					and time to build the future together
				</p>
				<br />
				<p>
					We are a partner who will listen to your goals, work together to
					develop the investment solution that uniquely suits your needs, and
					collaborate to support your success.
				</p>
			</InternalHero>
			<FiftyFifty reverseMobile={true} useRef={capitalRef}>
				<StaticImage
					src="../images/one-on-one.jpg"
					alt="First Security Building"
					aspectRatio={1 / 1}
					layout="constrained"
					width={640}
					height={640}
				/>
				<div className="extra-gap">
					<div className={typ.eyebrow}>Flexible Capital</div>
					<h2 className={com.browedHeader}>
						We support your vision for the future.
					</h2>
					<div className={typ.bodyLargeLight}>
						With the flexibility to structure our investment and partnership to
						align with your needs, we craft solutions that are tailored to you.
					</div>
				</div>
			</FiftyFifty>
			<FiftyFifty noMargin={true} useRef={ownershipRef}>
				<div className="extra-gap">
					<div className={typ.eyebrow}>Building Enduring Partnerships</div>
					<h2 className={com.browedHeader}>
						Great firms aren’t built in three to five years.
					</h2>
					<div className={typ.bodyLargeLight}>
						<p>
							Traditional Private Equity continues to have shorter time
							horizons, typically three to five years.
						</p>
						<p>
							Our view is that the best companies aren’t built in three to five
							years and that our companies can continue to compound over time.
						</p>
						<p>
							We have the desire and patience to partner with a long-term
							perspective.
						</p>
					</div>
				</div>
				<StaticImage
					src="../images/keep-majority-ownership.jpg"
					alt="First Security Building"
					aspectRatio={1 / 1}
					layout="constrained"
					width={640}
					height={640}
				/>
			</FiftyFifty>
			<div className={styles.alignmentChunk} ref={alignmentRef}>
				<div className={hasNames(grd.container, com.monobrow)}>Alignment</div>
				<h2 className={hasNames(grd.container, com.centerlineHeader)}>
					Because we are investing as partners and not as the sole or
					controlling shareholder, alignment is critical.
				</h2>
			</div>
			<TriplePoints
				points={[
					{
						image: <GrowthLeaf />,
						copy: (
							<>Can we structure an investment that accelerates your growth?</>
						),
					},
					{
						image: <IdeaLeaf />,
						copy: <>Do we both believe we can add value beyond capital?</>,
					},
					{
						image: <HighFiveLeaf />,
						copy: <>Do we see eye-to-eye on your vision?</>,
					},
				]}
			/>
			<WhyItMatters useRef={mattersRef} />
			<FiftyFifty
				reverseMobile={false}
				className={styles.beliefSpace}
				useRef={beliefRef}>
				<div className={styles.beliefGraphic}>
					<BeliefGraphic />
				</div>
				<div>
					<div className={typ.eyebrow}>Our belief</div>
					<h2 className={com.browedHeader}>
						We believe in investing in founder-led businesses.
					</h2>
					<div className={typ.bodyLargeLight}>
						No matter the sector, industry, or maturity of the business, we
						believe a founder-led company is the best place to invest our
						capital. This is especially true when the ownership mentality runs
						deep in the culture of the organization.
					</div>
					<Button
						to="/partnerships/founders"
						type="secondary"
						className={hasNames(com.leftAlignedButton)}>
						Learn More
					</Button>
				</div>
			</FiftyFifty>{" "}
			{/* CONTENT_MISSING */}
			<QuoteCarousel
				ref={quotesRef}
				label="What Founders Say"
				varient="white"
				quotes={[
					{
						image: (
							<StaticImage
								src={`../images/people/testimonial-wellington-altus-shaun-hauser.png`}
								alt="Shaun Hauser"
								placeholder="blurred"
								width={75}
								height={75}
								layout="fixed"
							/>
						),
						person: `Shaun Hauser`,
						position: `CEO & Co-Founder of Wellington-Altus`,
						bigText: `“ Most minority investors think they should get majority privileges. It was clear early on that Cynosure has experience in minority deals, and puts far more emphasis on getting to their potential new partners than creating complicated business terms. ”`,
					},
					{
						image: (
							<StaticImage
								src={`../images/people/testimonial-lendio-brock-blake.png`}
								alt="Brock Blake"
								placeholder="blurred"
								width={75}
								height={75}
								layout="fixed"
							/>
						),
						person: `Brock Blake`,
						position: `Co-Founder & CEO of Lendio`,
						bigText: `“ Cynosure is supportive, understanding, strategic, and founder-friendly. ”`,
					},
					{
						image: (
							<StaticImage
								src={`../images/people/testimonial-gold-medal-pools-josh-sandler.png`}
								alt="Josh Sandler"
								placeholder="blurred"
								width={75}
								height={75}
								layout="fixed"
							/>
						),
						person: `Josh Sandler`,
						position: `CEO of Sandler’s Gold Medal Pools`,
						bigText: `“ As a second-generation business, we have inherent challenges from a family and growth dynamic. Cynosure not only provided the opportunity to continue our vision and growth, but also the blueprint for success. ”`,
					},
					{
						image: (
							<StaticImage
								src={`../images/people/testimonial-plancorp-chris-kerckhoff.png`}
								alt="Chris Kerckhoff"
								placeholder="blurred"
								width={75}
								height={75}
								layout="fixed"
							/>
						),
						person: `Chris Kerckhoff`,
						position: `CEO of Plancorp`,
						bigText: `“ Cynosure's team is willing to act as a sounding board and thought partner. They have a long-term, strategic view on our business and are willing to commit time and resources to help us attain greater levels of success. ”`,
					},
					{
						image: (
							<StaticImage
								src={`../images/people/testimonial-sg-credit-partners-marc-cole.png`}
								alt="Marc Cole"
								placeholder="blurred"
								width={75}
								height={75}
								layout="fixed"
							/>
						),
						person: `Marc Cole`,
						position: `CEO of SG Credit Partners`,
						bigText: `“ Cynosure shares our long-term vision for transforming what was an early-stage lending firm into a scalable, multiproduct platform. Commercial credit is an inherently cyclical market and we deliberately focused on finding a partner with the background, patience and vision to weather cycles and capitalize on fast moving market opportunities. ”`,
					},
				]}
			/>
			<ContactUsFooter ref={contactRef} />
		</Frame>
	);
};

export default OurApproachPage;
